var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"col-sm-12 mt-2"},[_c('loading-overlay',{attrs:{"active":_vm.isLoading,"is-full-page":true,"loader":"bars"}}),_c('modalMachine',{attrs:{"modal":_vm.showModalMachine},on:{"closeModal":function($event){_vm.showModalMachine=false},"child-refresh":function($event){_vm.componentRefresh=true}}}),_c('CCol',{attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"5"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.machineCondition'),"horizontal":{label: 'col-auto', input:'col-sm-7'},"size":"sm","options":_vm.optionListCondition,"disabled":_vm.isLoading,"value":_vm.$store.state.maquina.filtroConditionId},on:{"update:value":function($event){return _vm.$set(_vm.$store.state.maquina, "filtroConditionId", $event)},"change":function($event){return _vm.conditionChange($event.target.value)}}})],1),_c('CCol',{attrs:{"sm":"5"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.machineType'),"horizontal":{label: 'col-auto', input:'col-sm-7'},"size":"sm","options":_vm.optionListType,"disabled":_vm.isLoading,"value":_vm.$store.state.maquina.filtroMachineTp},on:{"update:value":function($event){return _vm.$set(_vm.$store.state.maquina, "filtroMachineTp", $event)},"change":function($event){return _vm.typeChange($event.target.value)}}})],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"2"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.machine'),
                    placement: 'top-end'
                }),expression:"{\n                    content: $t('label.machine'),\n                    placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center",attrs:{"shape":"square","color":"add"},on:{"click":function($event){_vm.showModalMachine=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":""},scopedSlots:_vm._u([{key:"MachineId",fn:function({ index }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[(item.status)?_c('CBadge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.Status)+" ")]):_vm._e(),(!item.status)?_c('CBadge',{attrs:{"color":"danger"}},[_vm._v(" "+_vm._s(item.Status)+" ")]):_vm._e()],1)]}},{key:"acciones",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.edit')+_vm.$t('label.machine'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.edit')+$t('label.machine'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){_vm.showModalMachine=item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),(item.FgOperation)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.machineTypeRelationShip'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.machineTypeRelationShip'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.editCargo(item)}}},[_c('CIcon',{attrs:{"name":"cil-settings"}})],1):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }