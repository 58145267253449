<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CCol col="12" xl="12">
            <CCard class="mb-2">
                <CCardHeader class="text-center bg-dark text-white">
                    <b>{{$t('label.listMachine')}}</b>
                </CCardHeader>
                <CCardBody class="pt-2 pb-2">
                    <CModalExtended 
                        :show.sync="$store.state.maquina.CollapseCarga"
                        size="xl"
                        color="dark"
                        :closeOnBackdrop="false"
                    >
                        <template #header>
                            <h5 class="modal-title"> {{$t('label.machineTypeRelationShip')}}</h5>
                            <button type="button" aria-label="Close" class="close"
                                @click="$store.commit('maquina/collapseCarga',false)" >x</button>
                        </template>
                        <appCargaMachine></appCargaMachine>
                        <template #footer-wrapper>
                            <div/>
                        </template>
                   </CModalExtended>
                        <!--<CCollapse :show="!$store.state.maquina.CollapseMachine"-->
                            <div>
                            <CustomTabs  :active-tab="Tab" @update:activeTab="handleTab">
                                <CustomTab icon="machineCondition" :title="$t('label.machineCondition')">
                                    <appCondition :Tab="Tab" class="ml-1 mr-1"/>
                                </CustomTab>
                                <CustomTab icon="classificationMachine" :title="$t('label.machineClassification')">
                                    <appClassificationMachine :Tab="Tab" class="ml-1 mr-1"/>
                                </CustomTab>
                                <CustomTab icon="machineType" :title="$t('label.machineType')">
                                    <appTipeMachine :Tab="Tab" class="ml-1 mr-1"/>
                                </CustomTab>
                                <CustomTab icon="machinery" :title="$t('label.machinery')">
                                    <appMaquina :Tab="Tab" class="ml-1 mr-1" />
                                </CustomTab>
                            </CustomTabs>
                            </div>
                        <!--</CCollapse-->
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<style>
    .center-cell {
        text-align: center;
        vertical-align: middle;
    }

    .modal-body {
        margin:0px;
        padding: 0px;
    }

    .tpl-ul {
        height: 700px !important;
    }

    #li-data {
        height: 690px !important;
        overflow: hidden;
    }

    #li-data table th,
    table td {
        padding: 0.4rem !important;
    }

    #li-data .text {
        width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

</style>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';

    import appMaquina from './app-maquina';
    import appCondition from './condicion-maquina/app-condicion-maquina';
    import appClassificationMachine from './clasificacion-maquina/app-clasificacion-maquina';
    import appTipeMachine from './tipo-maquina/app-tipo-maquina';

    import appCargaMachine from './container/app-container-carga';

    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';

    //methods
    function tabChecked(val) {
        return this.tabIndex === 'tab3';
    }
    function tabFormChecked(val) {
        return this.tabFormIndex === val;
    }
    function tabChanged(val){
        this.$store.state.maquina.tabIndex = 'tab3';
    }
    function tabFormChanged(val){
        this.$store.state.maquina.tabFormIndex = val;
    }
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING /*|| this.apiState === ENUM.INIT*/){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    //created
    function created() {
        this.$store.state.maquina.CollapseMachine = false;
        this.$store.state.maquina.CollapseCarga = false; 
        this.$store.state.maquina.tabIndex = "tab3";
        this.$store.state.maquina.tabFormIndex = "tabForm1";
        //this.$store.dispatch('maquina/getAlllist',{CompanyBranchId:this.branch.CompanyBranchId});
    }
    //watch
    function apiState(newQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? this.$t('label.unexpectedError') :  this.messageError)
                });
                this.$store.state.maquina.messageError = '';
            }
        }
    }

    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? this.$t('label.unexpectedError') :  this.messageError)
                });
                this.$store.state.maquina.messageError = '';
            
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? this.$t('label.completedSuccessfully') :  this.messageError)
                });
                this.$store.state.maquina.messageError = '';
                
                break;
            default:
                break;
        }
    }
//Data
function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    Tab: 0,   
  }
}

function handleTab(tab) {
  this.Tab = tab;
}
    export default {
        name: 'app-maquina',
        data,
        created,
        methods: {
            tabChecked,
            tabChanged,
            tabFormChecked,
            tabFormChanged,
            handleTab, 
        },
        computed:{
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                apiState: state => state.maquina.apiState, 
                apiStateForm: state => state.maquina.apiStateForm, 
                messageError: state => state.maquina.messageError, 
                tabIndex: state => state.maquina.tabIndex, 
                tabFormIndex:state => state.maquina.tabFormIndex, 
                branch: state => state.auth.branch 
            }),
            
        },
        components: {
            appCargaMachine,
            appMaquina,
            appCondition,
            appClassificationMachine,
            appTipeMachine,
            CustomTabs,
            CustomTab,
        },
        watch:{
            apiState,
            apiStateForm, 
            active: function(newVal) { 
                if(newVal)
                    this.activeTab = 0;
                }
        }
    }
</script>