
<template>
    <CRow class="col-sm-12 mt-2">
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars"/>
            <modalMachine
                :modal="showModalMachine" 
                @closeModal = "showModalMachine=false" 
                @child-refresh = "componentRefresh=true"  
            />
        <CCol sm="12">
            <CRow>
               <CCol sm="5">
                    <CSelect
                        :label="$t('label.machineCondition')"
                        :horizontal="{label: 'col-auto', input:'col-sm-7'}"
                        size="sm"
                        :options="optionListCondition"
                        :disabled="isLoading"
                        :value.sync="$store.state.maquina.filtroConditionId"
                        @change="conditionChange($event.target.value)"
                      
                    />
                </CCol>
                <CCol sm="5">
                    <CSelect
                        :label="$t('label.machineType')"
                        :horizontal="{label: 'col-auto', input:'col-sm-7'}"
                        size="sm"
                        :options="optionListType"
                        :disabled="isLoading"
                        :value.sync="$store.state.maquina.filtroMachineTp"
                        @change="typeChange($event.target.value)"
                    />
                </CCol>
                <CCol sm="2" class="d-flex align-items-center justify-content-end">
                    <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center"
                    v-c-tooltip="{
                        content: $t('label.machine'),
                        placement: 'top-end'
                    }"
                    @click="showModalMachine=true"
                    >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nueva') }}</span>       
                    </CButton>
                </CCol> 
            </CRow>
        </CCol>
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                column-filter
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :items="formatedItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                pagination
            >
                <template #MachineId="{ index }">
                    <td class="center-cell">
                        {{index+1}}
                    </td>
                </template>
                <template #Status="{item}">
                    <td class="center-cell">
                        <CBadge v-if="item.status" color="success">
                            {{item.Status}}
                        </CBadge>
                        <CBadge v-if="!item.status" color="danger">
                            {{item.Status}}
                        </CBadge>
                    </td>
                </template>
                <template #acciones="{item}">
                    <td class="center-cell">
                        <CButton
                            color="edit"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.edit')+$t('label.machine'),
                                placement: 'top-end'
                            }"
                            @click="showModalMachine=item"
                        >
                            <CIcon name="pencil"/>
                        </CButton>
                        <CButton
                            color="watch"
                            size="sm"
                            class="mr-1"   
                            v-c-tooltip="{
                                content: $t('label.machineTypeRelationShip'),
                                placement: 'top-end'
                            }"
                            v-if="item.FgOperation"
                            @click="editCargo(item)"

                        >
                            <CIcon name="cil-settings"/>
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>  
</template>

<script>
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
    import modalMachine from '@/pages/maquina/container/modal-machine';

    //data
    function data() {
        return {
            activePage: 1,
            condicion_id: '',
            tipo_id: '',
            showModalMachine: '',
            componentRefresh: false,
            isLoading:false
        }
    }

    //methods
    function editCargo(item){
        this.$store.state.maquina.idState = item.MachineId;
        this.$store.commit('maquina/collapseCarga',true);
    }

    async function conditionChange(value){
        this.condicion_id= value;
        const TpMachineId = this.tipo_id; 
        //this.$store.state.contenedores.filtroConditionId = value; 
        this.$store.state.maquina.filtroConditionId = value; 
        const CompanyBranchId = this.branch.CompanyBranchId;
        const MachineConditionId = value;
        this.isLoading = true;
        await this.$store.dispatch('maquina/getMachinelist',{CompanyBranchId,MachineConditionId,TpMachineId});
        this.isLoading = false;
    }

    async function typeChange(value){
        this.tipo_id=  value;
        const MachineConditionId = this.condicion_id;
        //this.$store.state.contenedores.filtroMachineTp = value;
        this.$store.state.maquina.filtroMachineTp = value;
        const CompanyBranchId = this.branch.CompanyBranchId;    
       
        const TpMachineId = value;    
        this.isLoading = true;
        await this.$store.dispatch('maquina/getMachinelist',{CompanyBranchId,MachineConditionId,TpMachineId});
        this.isLoading = false;
    }
    //computed
    function computedItems () {
        return this.$store.getters["maquina/myDataMachinetable"];
    }

    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }

    function optionListCondition(){
        if(this.myDataMachineCondition.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataMachineCondition.map(function(e){
                if(e.FgActMachineCondition){
                    chart.push({
                        value: e.MachineConditionId, 
                        label: e.MachineConditionName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListType(){
        let _lang = this.$i18n.locale;
        if(this.myDataMachineTp.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataMachineTp.map(function(e){
                if(e.FgActTpMachine){
                    chart.push({
                        value: e.TpMachineId, 
                        label: _lang=='en' ? e.TpMachineNameEn : e.TpMachineNameEs
                    })
                }
            })
            return chart;
        }
    }
    function  fields() {
        return [
            { key: 'MachineId',label: '#', filter: false,_style: 'width:1%; text-align:center;', },
            { key: 'MachineConditionName',label:this.$t('label.condition'), _style:'min-width:100px;', _classes:'text-center' },
            { key: 'TpMachineName',label:this.$t('label.type'), _style:'min-width:100px;', _classes:'text-center' },
            { key: 'MachineName',label:this.$t('label.machine'), _style:'min-width:100px;', _classes:'text-center' },
            { key: 'BrandName',label:this.$t('label.brand'), _style:'min-width:100px;', _classes:'text-center' },
            { key: 'ModelName',label:this.$t('label.model'), _style:'min-width:100px;', _classes:'text-center' },
            { key: 'Cod',label:this.$t('label.serialUnique'), _style:'min-width:100px;', _classes:'text-center' },
            { key: 'UltimoUsuario', label:this.$t('label.user'), _style: 'width:1%;', _classes:'text-center' },
            { key: 'FormatedDate', label:this.$t('label.date'), _style: 'width: 1%;', _classes:'text-center'},
            { key: 'Status',label:this.$t('label.status'), _style:'width:1%;', _classes:'text-center' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:9%', 
                sorter: false, 
                filter: false,
                _classes:'text-center'
            }
      ]
    }
    //watch
    
    async function tabIndex(newValue){
        if(newValue === "tab3"){
            const MachineConditionId = this.filtroConditionId;
            const CompanyBranchId = this.branch.CompanyBranchId;
            const TpMachineId = this.filtroMachineTp;
            this.isLoading = true;
            await this.$store.dispatch('maquina/getMachinelist',{CompanyBranchId,MachineConditionId,TpMachineId});
            this.isLoading = false;
        }
    }
   
    /*function CollapseMachine(newValue){
        if(newValue === false){
            const MachineConditionId = this.filtroConditionId;
            const CompanyBranchId = this.branch.CompanyBranchId;
            const TpMachineId = this.filtroMachineTp;

            this.$store.dispatch('maquina/getMachinelist',{CompanyBranchId,MachineConditionId,TpMachineId});
        }
    }*/
    
    async function CollapseCarga(newValue){
        if(newValue === false){
            const MachineConditionId = this.filtroConditionId;
            const CompanyBranchId = this.branch.CompanyBranchId;
            const TpMachineId = this.filtroMachineTp;
            this.isLoading = true;
            await this.$store.dispatch('maquina/getMachinelist',{CompanyBranchId,MachineConditionId,TpMachineId});
            this.isLoading = false;
        }
    }
    export default {
        name: 'app-maquina',
        data,
        props:{Tab: Number}, 
        mixins: [GeneralMixin],   
        methods: {
            editCargo,
            conditionChange,
            typeChange,
        },
        computed: {
            fields,
            computedItems,
            formatedItems,
            optionListCondition,
            optionListType,
            ...mapState({
                tabIndex: state => state.maquina.tabIndex,
                myDataMachineTp: state => state.maquina.myDataMachineTp,
                myDataMachineCondition: state => state.maquina.myDataMachineCondition,
                filtroConditionId: state => state.maquina.filtroConditionId,
                filtroMachineTp: state => state.maquina.filtroMachineTp,
                CollapseMachine: state=> state.maquina.CollapseMachine,
                CollapseCarga: state=> state.maquina.CollapseCarga,
                branch: state => state.auth.branch,
                idState: state => state.maquina.idState,
            })
        },
        watch:{
            tabIndex,
            //CollapseMachine,
            CollapseCarga,
            componentRefresh: function () {
                if (this.componentRefresh) {
                    this.componentRefresh = false;
                    this.$store.dispatch('maquina/getAlllist',{CompanyBranchId:this.branch.CompanyBranchId});
                }
            },
             Tab: async function (val){
                if(val === 3){
                    this.isLoading = true;
                    await this.$store.dispatch('maquina/getMachinelist',{CompanyBranchId:this.branch.CompanyBranchId ,MachineConditionId: this.MachineConditionId ,TpMachineId: this.TpMachineId});
                    await this.$store.dispatch('maquina/getAlllist',{CompanyBranchId:this.branch.CompanyBranchId});
                    this.isLoading = false;
                }
        }
        },
        components: {
            modalMachine,
        }
    }   
</script>

<style lang="scss" >
.center-cell {
  text-align: center;
}
 
</style>